// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audioreverb-js": () => import("./../../src/pages/audioreverb.js" /* webpackChunkName: "component---src-pages-audioreverb-js" */),
  "component---src-pages-blogs-js": () => import("./../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tuner-js": () => import("./../../src/pages/tuner.js" /* webpackChunkName: "component---src-pages-tuner-js" */),
  "component---src-templates-markdown-js": () => import("./../../src/templates/markdown.js" /* webpackChunkName: "component---src-templates-markdown-js" */)
}

